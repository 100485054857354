/** @format */

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Flex,
  Link,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/react";
import {HamburgerIcon} from "@chakra-ui/icons";
import ContactForm from "../../ContactForm";
import {PATHS} from "../../../utils/constants";

const Header = ({path}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isOpen: isOpenContactForm,
    onOpen: onOpenContactForm,
    onClose: onCloseContactForm,
  } = useDisclosure();

  const Logo = path ? (
    <Text
      fontSize="2xl"
      fontFamily="cursive"
      color="text.200"
      fontWeight="bold"
    >
      AphroTechConsult
    </Text>
  ) : (
    <Link
      href={PATHS.HOME}
      _active={{textDecoration: "none"}}
      _hover={{textDecoration: "none"}}
      _visited={{textDecoration: "none"}}
    >
      <Text
        fontSize="2xl"
        fontFamily="cursive"
        color="text.200"
        fontWeight="bold"
      >
        AphroTechConsult
      </Text>
    </Link>
  );

  return (
    <Flex
      justify="space-between"
      p={4}
      bg="#952782"
      position="fixed"
      top={0}
      width="100%"
      data-testid="header"
      zIndex={4}
    >
      {Logo}
      <Box display={{base: "none", md: "block"}}>
        <HStack
          spacing={8}
          justify="space-between"
          color="text.200"
          fontWeight={700}
        >
          <Link href="/services">Services</Link>
          <Link href="/works">Works</Link>
          <Link href="/about">About us</Link>
          <Button variant="contact" onClick={onOpenContactForm}>
            Contact Us
          </Button>
        </HStack>
      </Box>
      <Box display={{base: "block", md: "none"}}>
        <HamburgerIcon onClick={onOpen} />
      </Box>
      <Drawer
        placement="top"
        isOpen={isOpen}
        onClose={onClose}
        size="full"
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody display="flex" justifyContent="center" bg="#9c297e">
            <VStack
              align="center"
              justify="center"
              color="text.200"
              fontSize="30px"
            >
              <Link href="/">Home</Link>
              <Link href="/services">Services</Link>
              <Link href="/works">Works</Link>
              <Link href="/about">About us</Link>
              <Button variant="contact" onClick={onOpenContactForm}>
                Contact Us
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <ContactForm
        isOpen={isOpenContactForm}
        onClose={onCloseContactForm}
        onCloseHambugerMenu={onClose}
      />
    </Flex>
  );
};

Header.propTypes = {
  path: PropTypes.bool,
};
export default Header;
