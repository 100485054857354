/** @format */

import React from "react";
import PropTypes from "prop-types";
import {Box} from "@chakra-ui/react";
import Hero from "../../Hero/hero";

const LandingPageLayout = ({children}) => {
  return (
    <>
      <Hero />
      <Box width="full">{children}</Box>
    </>
  );
};

LandingPageLayout.propTypes = {
  children: PropTypes.object,
};

export default LandingPageLayout;
