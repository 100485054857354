/** @format */

import {
  Box,
  Container,
  Heading,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import {PATHS} from "../../../utils/constants";

const Footer = ({path}) => {
  const Logo = path ? (
    <Text
      fontSize="2xl"
      fontFamily="cursive"
      color="text.200"
      fontWeight="bold"
    >
      AphroTechConsult
    </Text>
  ) : (
    <Link
      href={PATHS.HOME}
      _active={{textDecoration: "none"}}
      _hover={{textDecoration: "none"}}
      _visited={{textDecoration: "none"}}
    >
      <Text
        fontSize="2xl"
        fontFamily="cursive"
        color="text.200"
        fontWeight="bold"
      >
        AphroTechConsult
      </Text>
    </Link>
  );

  return (
    <Container py={8} bg="gray.900" color="text.200" minW="360px">
      <Stack
        spacing={{base: 10, sm: 5}}
        direction={{base: "column", sm: "row"}}
        justify="space-between"
        align="start"
      >
        <VStack align="start">
          {Logo}
          <Text fontWeight="bold">info@aphrotechconsult.com</Text>
          <Stack direction={"row"} spacing={8}>
            <StaticImage
              alt="facebook"
              src="../../../static/svg/linkedin.svg"
              width={40}
            />

            <StaticImage
              alt="facebook"
              src="../../../static/svg/twitter.svg"
              width={40}
            />
          </Stack>
        </VStack>
        <VStack align="start">
          <Link variant="primary" href={PATHS.HOME}>
            Home
          </Link>
          <Link variant="primary" href={PATHS.SERVICES}>
            Services
          </Link>
          <Link variant="primary" href={PATHS.WORKS}>
            Works
          </Link>
          <Link variant="primary" href={PATHS.ABOUT_US}>
            About us
          </Link>
        </VStack>
        <VStack align="start" spacing={8}>
          <Box>
            <Heading fontSize="lg">Ghana</Heading>
            <Text fontSize="md">
              Haile Selassie St <br />
              Accra, Ghana <br />
              info@aphrotechconsult.com
            </Text>
          </Box>
        </VStack>
      </Stack>
    </Container>
  );
};

Footer.propTypes = {
  path: PropTypes.bool,
};

export default Footer;
