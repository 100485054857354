/** @format */

export default {
  global: {
    "html, body": {
      background: "bg.100",
      color: "black",
      lineHeight: "tall",
    },
  },
};
