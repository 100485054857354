/** @format */

export default {
  baseStyle: {
    px: {
      base: 4,
      sm: 8,
      md: 12,
      lg: 16,
    },
    maxWidth: "none",
  },
  variants: {
    form: {
      maxWidth: "522px",
      px: 0,
    },
  },
};
