/** @format */

export default {
  baseStyle: {},
  variants: {
    // eslint-disable-next-line no-unused-vars
    primary: (props) => ({
      fontWeight: "bold",
    }),
    // eslint-disable-next-line no-unused-vars
    contact: (props) => ({
      bg: "#199319",
      color: "text.200",
      fontWeight: "bold",
      py: 4,
      px: 12,
      borderRadius: 10,
    }),
  },
};
