/** @format */

import React from "react";
import {ChakraProvider} from "@chakra-ui/react";
import theme from "./src/theme";
import {LandingPageLayout, PageLayout} from "./src/components/Layout";
import {Header} from "./src/components/Header";
import {Footer} from "./src/components/Footer";

import "./src/theme/foundations/style.css";

export const wrapPageElement = ({element}) => {
  const isLandingPage = element.props.location.pathname === "/";

  return (
    <ChakraProvider theme={theme}>
      <Header path={isLandingPage} />
      {isLandingPage ? (
        <LandingPageLayout>{element}</LandingPageLayout>
      ) : (
        <PageLayout>{element}</PageLayout>
      )}
      <Footer path={isLandingPage} />
    </ChakraProvider>
  );
};
