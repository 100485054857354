/** @format */

import React from "react";
import {Container, Flex, VStack} from "@chakra-ui/react";
import Typewriter from "typewriter-effect";

const Hero = () => {
  return (
    <>
      <Flex
        h="70vh"
        minW="360px"
        bgGradient={`linear(111.65deg,#8a2387 .41%,#e94057 72.26%,#f27121 109.08%)`}
        justify="center"
        align="center"
        data-testid="hero"
      >
        <Container data-testid="hero-text-container">
          <VStack spacing={8} px={8} height={"200px"}>
            <h1 style={{color: "white", fontSize: "30px"}}>
              <Typewriter
                options={{
                  strings: [
                    "We provide optimized IT solutions for your business!",
                    "We build cutting-edge solutions for your digital transformation",
                  ],
                  autoStart: true,
                  delay: 200,
                  pauseFor: 5000,
                  loop: true,
                }}
              />
            </h1>
          </VStack>
        </Container>
      </Flex>
    </>
  );
};

export default Hero;
