/** @format */

export default {
  baseStyle: {
    borderRaduis: "base",
    lineHeight: "normal",
    _focus: {
      outline: "none",
    },
  },
  variants: {
    // eslint-disable-next-line no-unused-vars
    primary: (props) => ({
      bg: "red",
    }),
    // eslint-disable-next-line no-unused-vars
    contact: (props) => ({
      bg: "#ec193e",
      color: "text.200",
      borderRadius: "10px",
      p: 2,
    }),
  },
};
