/** @format */

import {extendTheme} from "@chakra-ui/react";

//Foundational overides
import styles from "./foundations/styles";
import breakpoints from "./foundations/breakpoints";
import colors from "./foundations/colors";
import sizes from "./foundations/sizes";
import spacing from "./foundations/spacing";
import typography from "./foundations/typography";

//
import Container from "./components/base/container";
import Button from "./components/base/button";
import Drawer from "./components/base/drawer";
import Link from "./components/base/link";

const themeOverides = {
  // Foundational Overides
  styles,
  sizes,
  spacing,
  colors,
  breakpoints,
  ...typography,

  // Base components
  components: {Container, Button, Drawer, Link},
};

export default extendTheme(themeOverides);
