/** @format */

export default {
  size: {
    11: "2.75rem",
    17: "4.25rem",
    18: "2.75rem",
    21: "2.75rem",
    22: "2.75rem",
    26: "2.75rem",
  },

  container: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1440px",
  },
};
