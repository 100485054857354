/** @format */

import {theme as base} from "@chakra-ui/react";

export default {
  fonts: {
    body: `'Source Sans Pro', ${base.fonts?.heading}`,
    heading: `'Source Sans Pro', ${base.fonts?.heading}`,
  },
  fontSizes: {
    base: "1rem", // 16px
    xs: "0.75rem", // 12px
    sm: "0.75rem", // 14px
    md: "1rem", // 16px
    lg: "1.125rem", //
    xl: "1.25rem", //
    "2xl": "1.5rem", //
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  fontWeight: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    semiBold: 800,
    extraBold: 900,
  },
};
