/** @format */

import React from "react";
import PropTypes from "prop-types";
import {Box} from "@chakra-ui/react";

const PageLayout = ({children}) => {
  return (
    <>
      <Box width="full">{children}</Box>
    </>
  );
};

PageLayout.propTypes = {
  children: PropTypes.object,
};

export default PageLayout;
